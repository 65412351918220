<template>
	<div class="">
		<el-dialog title="立即办理" :visible.sync="show" width="510px" :close-on-click-modal="false" :before-close="handleClose" @opened="openClick">
			<div class="title-text flex flex-col-center">
				<img src="@/assets/order/warning_icon.png" alt="" />
				<span>请确保已在内部业务系统完成了订单创建及办理！</span>
			</div>
			<el-form ref="ruleForm" :model="form" :rules="rules" label-width="110px">
				<el-form-item label="关联业务系统" prop="service_system">
					<el-select v-model="form.service_system" placeholder="请选择关联业务系统">
					    <el-option v-for="item in sysList" :key="item.id" :label="item.dict_name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="关联单号" prop="assoc_odd_no">
					<el-input class="input-w" v-model="form.assoc_odd_no" placeholder="请填写关联业务系统单号"></el-input>
				</el-form-item>
				<el-form-item label="办理人员" prop="handle_staff">
					<el-select v-model="form.handle_staff" placeholder="请选择办理人员">
					    <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="办理业务时间" prop="handle_time">
					<el-date-picker v-model="form.handle_time" :picker-options="pickerOptions" type="datetime" placeholder="请选择办理业务时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="备注" prop="handle_notes">
					<el-input class="input-w" v-model="form.handle_notes" type="textarea" :rows="4" placeholder="如有其他备注信息，可在此填写" maxlength="200" show-word-limit></el-input>
				</el-form-item>
				<el-form-item>
					<el-button @click="handleClose">取消</el-button>
					<el-button type="primary" @click="enterClick('ruleForm')">确定</el-button>
				</el-form-item>
			</el-form>
		
		</el-dialog>
	</div>
</template>

<script>
	import {timeFormat} from "@/utils/index.js"
	export default {
		name: 'handle-order-popup',
		props: {
			show: false,
			msg: {}
		},
		data(){
			return {
				form: {
					id: 0,
					service_system: '',
					assoc_odd_no: '',
					handle_staff: '',
					handle_time: '',
					handle_notes: ''
				},
				rules: {
					service_system: [{required: true, message: '请选择关联业务系统', trigger: 'change' }],
					assoc_odd_no: [{required: true, message: '请填写关联业务系统单号', trigger: 'blur' }],
					handle_staff: [{required: true, message: '请选择办理人员', trigger: 'change' }],
					handle_time: [{required: true, message: '请选择办理业务时间', trigger: 'blur' }],
					handle_notes: [{required: false, message: '请填写备注', trigger: 'blur' }]
				},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					}
				},
				sysList: [],
				userList: []
			}
		},
		created() {
			
		},
		methods: {
			enterClick(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.form.handle_time = timeFormat(this.form.handle_time, 'yyyy-mm-dd hh:MM:ss');
						this.$api.order_handle(this.form).then(res=>{
							this.$message({type: 'success', message: '提交成功！'});
							this.$emit('change');
							this.$emit('close');
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			openClick(){
				this.form.id = this.msg.id;
				//业务系统
				this.$api.dictionary_option_list(28).then(res=>{
					this.sysList = res.data;
				})
				//办理人员
				this.$api.order_handle_admin().then(res=>{
					this.userList = res.data;
				})
				
			},
			handleClose(){
				this.$emit('close');
			}
		},
	}
</script>

<style lang="scss" scoped>
	.title-text{height: 40px;background: #FFF5F5;border-radius: 2px;font-size: 12px;color: #FD5451;margin: -22px 0 20px 0;
		img{width: 14px;height: 14px;margin: 0 10px;}
	}
	.el-select{width: 350px;}
	.input-w{width: 350px;}
	.el-date-picker{width: 350px;}
	.el-date-editor{width: 350px;}
</style>