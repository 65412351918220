<template>
	<div class="order-details-main">
		<div class="status-content flex flex-col-center">
			<div class="title-content">
				<div :class="['status-text', allData.order_status<4?'color-green':'']">
					{{getOrderStatus(allData.order_status)}}
					<span style="color: #FD5451;">{{allData.refund_status==4?'(退款驳回)':(allData.refund_status==2?'(退款中)':'')}}</span>
				</div>
				<div class="num-text">订单编号：{{allData.order_no}}</div>
			</div>
			<order-status-item-vue :msg="allData"></order-status-item-vue>
		</div>
		<customer-info-item-vue :msg="allData"></customer-info-item-vue>
		<refund-info-item-vue :msg="allData" v-if="allData.order_status==5||allData.refund_status==4||allData.refund_status==2"></refund-info-item-vue>
		<handle-info-item-vue :msg="allData" v-if="allData.order_status > 2"></handle-info-item-vue>
		
		<div class="product-info-content">
			<div class="info-title">产品信息</div>
			<div class="tabel-content">
				<el-table :data="tableData" style="width: 100%" :header-cell-style="{height:'40px;',background:'#F6F7F9',color:'#6C7880'}" >
				    <el-table-column prop="date" label="产品信息" width="335">
						<template  slot-scope="scope">
							<div class="flex flex-col-center">
								<img class="product-img" v-for="(item, index) in scope.row.product_img" :key="index" :src="item" alt="" />
								<span class="sm-text">{{scope.row.name}}</span>
							</div>
						</template>
					</el-table-column>
				    <el-table-column prop="price" label="价格" width="279">
						<template  slot-scope="scope">
							<span style="color: #FD5451;">￥{{scope.row.price}}</span>
						</template>
					</el-table-column>
				    <el-table-column prop="num" label="订购时长" width="190">
						<template  slot-scope="scope">
							<span >{{scope.row.num}}月</span>
						</template>
					</el-table-column>
				    <el-table-column prop="total_amount" label="订单金额" width="188">
						<template  slot-scope="scope">
							<span style="color: #FD5451;">￥{{scope.row.total_amount}}</span>
						</template>
					</el-table-column>
				    <el-table-column prop="dictionary.dict_name" label="支付方式" width="188"></el-table-column>
				</el-table>
			</div>
			<div class="btn-content">
				<el-button type="primary" class="enter-btn" @click="enterOrderClick" v-if="allData.order_status==1">立即确认</el-button>
				<el-button class="clear-btn" @click="showOrderPopup" v-if="allData.order_status==1">取消订单</el-button>
				<el-button type="primary" class="enter-btn" @click="handleClick" v-if="allData.order_status==2">立即办理</el-button>
				<el-button type="primary" class="enter-btn" @click="overOrderClick" v-if="allData.order_status==3&&allData.refund_status==0">办结订单</el-button>
				<el-button class="clear-btn" @click="subRefundClick" v-if="allData.order_status==3&&allData.refund_status==0">发起退款</el-button>
				<el-button type="primary" class="enter-btn" @click="exaClick" v-if="allData.order_status==3&&allData.refund_status==2">审核退款</el-button>
			</div>
		</div>
		<cancel-order-popup-vue :show="orderData.show" :msg="orderData.data" @change="initData"  @close="closeClick"></cancel-order-popup-vue>
		<handle-order-popup-vue :show="handleData.show" :msg="handleData.data" @change="initData" @close="closeClick"></handle-order-popup-vue>
		<exa-order-popup-vue :show="exaData.show" :msg="exaData.data" @change="initData" @close="closeClick"></exa-order-popup-vue>
	</div>
</template>

<script>
	import orderStatusItemVue from '../components/order-status-item.vue';
	import customerInfoItemVue from '../components/customer-info-item.vue';
	import refundInfoItemVue from '../components/refund-info-item.vue';
	import handleInfoItemVue from '../components/handle-info-item.vue';
	import cancelOrderPopupVue from '../components/cancel-order-popup.vue';
	import handleOrderPopupVue from '../components/handle-order-popup.vue';
	import exaOrderPopupVue from '../components/exa-order-popup.vue';
	export default {
		components: {
			orderStatusItemVue, customerInfoItemVue, refundInfoItemVue, handleInfoItemVue, cancelOrderPopupVue, handleOrderPopupVue, exaOrderPopupVue
		},
		data(){
			return {
				pageData: {},//
				allData: {},//所有数据
				tableData: [],//产品信息
				orderData: {show: false, data: {}},//取消订单弹窗数据
				handleData: {show: false, data: {}},//立即办理弹窗数据 
				exaData: {show: false, data: {}},//审核退款弹窗数据
			}
		},
		created() {
			this.pageData = this.$route.query.data;
			this.initData();
		},
		methods: {
			initData(){
				this.$api.order_details(this.pageData).then(res=>{
					this.allData = res.data;
					Object.assign(res.data.order_product, {total_amount: res.data.total_amount});
					this.tableData = [res.data.order_product];
				})
			},
			//确认订单
			enterOrderClick(e){
				this.$confirm('是否确认办理该订单？', '提示', { confirmButtonText: '是', cancelButtonText: '否',type: 'warning'}).then(() => {
					this.$api.orderOrder_confirm(this.allData.id).then(res=>{
						this.$message({type: 'success', message: '确认订单成功！'});
						this.initData();
					})
				})
			},
			//办结订单
			overOrderClick(e){
				this.$confirm('办结后，订单状态将变成已完结，是否确认办结？', '提示', { confirmButtonText: '是', cancelButtonText: '否',type: 'warning'}).then(() => {
					this.$api.order_finish(this.allData.id).then(res=>{
						this.$message({type: 'success', message: '办结订单成功！'});
						this.initData();
					})
				})
			},
			//审核退款
			exaClick(){
				this.exaData.data = this.allData;
				this.exaData.show = true;
			},
			//发起退款
			subRefundClick(e){
				this.orderData.data = this.allData;
				this.orderData.show = true;
			},
			//立即办理
			handleClick(){
				this.handleData.data = this.allData;
				this.handleData.show = true;
			},
			//取消订单显示弹窗
			showOrderPopup(){
				this.orderData.data = this.allData;
				this.orderData.show = true;
			},
			//关闭弹窗
			closeClick() {
				this.orderData.show = false;
				this.handleData.show = false;
				this.exaData.show = false;
			},
			//获取订单状态内容
			getOrderStatus(num) {
				let str = '待确认';
				switch (num){
					case 1:
						str = '待确认';
						break;
					case 2:
						str = '待办理';
						break;
					case 3:
						str = '待验收';
						break;
					case 4:
						str = '已完结';
						break;
					case 5:
						str = '已关闭';
						break;
					default:
						break;
				}
				return str;
			}
		},
	}
</script>

<style lang="scss" scoped>
	.order-details-main{
		.status-content{height: 120px;background: #FFFFFF;padding: 0 30px;
			.title-content{width: 290px;border-right: 1px solid #E1E8EC;
				.status-text{font-size: 18px;color: #242D33;}
				.color-green{color: #04CD83;}
				.num-text{font-size: 12px;color: #6C7880;margin-top: 12px;}
			}
		}
		.product-info-content{margin-top: 18px;background: #FFFFFF;padding: 30px;
			.info-title{font-size: 18px;color: #242D33;position: relative;margin-bottom: 29px;
				&:before{position: absolute;content: '';left: -30px;top: 4px;width: 4px;height: 20px;background: #3984F5;}
			}
			.tabel-content{border: 1px solid #DCE5EC;width: 1180px;
				.product-img{width: 70px;height: 70px;background: #CEDAE0;border-radius: 6px;margin-right: 10px;}
				.sm-text{font-size: 12px;color: #242D33;margin-left: 10px;}
			}
			.btn-content{margin-top: 39px;
				.enter-btn{width: 120px;height: 40px;background: #3984F5;border-radius: 4px;padding: 0;margin-right: 10px;}
				.clear-btn{width: 120px;height: 40px;background: #EEF1F3;border-radius: 4px;padding: 0;color: #6C7880;}
			}
			
		}
		
	}
	
</style>