<template>
	<div class="handle-info-main">
		<div class="info-title">办理信息</div>
		<div class="text-content flex">
			<div class="text-left">
				<div class="name-text">
					关联业务系统：<span class="company-text">{{msg.service_system?msg.service_system:'--'}}</span>
				</div>
				<div class="name-text">
					办理人：<span class="company-text">{{msg.handle_staff?msg.handle_staff:'--'}}</span>
				</div>
				<div class="name-text">
					备注：<span class="company-text">{{msg.handle_notes?msg.handle_notes:'--'}}</span>
				</div>
			</div>
			<div class="text-right">
				<div class="name-text">
					关联单号：<span class="company-text">{{msg.assoc_odd_no?msg.assoc_odd_no:'--'}}</span>
				</div>
				<div class="name-text">
					办理业务时间：<span class="company-text">{{msg.handle_time?msg.handle_time:'--'}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'handle-info-item',
		props: {
			msg: {}
		},
		data(){
			return {
				
			}
		},
		created() {
			
		},
		methods: {
			
		},
	}
</script>

<style lang="scss" scoped>
	.handle-info-main{margin-top: 18px;background: #FFFFFF;padding: 30px;
		.info-title{font-size: 18px;color: #242D33;position: relative;margin-bottom: 29px;
			&:before{position: absolute;content: '';left: -30px;top: 4px;width: 4px;height: 20px;background: #3984F5;}
		}
		.text-content{
			.text-left{margin-right: 270px;
				.name-text{font-size: 12px;color: #6C7880;margin-bottom: 20px;
					.company-text{color: #242D33;}
				}
			}
			.text-right{
				.name-text{font-size: 12px;color: #6C7880;margin-bottom: 20px;
					.company-text{color: #242D33;}
				}
			}
		}
		
	}
	
</style>