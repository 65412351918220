<template>
	<div class="user-info-content">
		<div class="info-title">客户信息</div>
		<div class="text-content flex flex-col-center">
			<div class="text-left">
				<div class="name-text">
					企业名称：<span class="company-text">{{msg.company?msg.company.company:'--'}}</span>
				</div>
				<div class="name-text">
					办理业务地区：<span class="company-text">{{msg.prov+'-'+msg.city+'-'+msg.county}}</span>
				</div>
				<div class="name-text">
					联系人：<span class="company-text">{{msg.contacts}}</span>
				</div>
			</div>
			<div class="text-right">
				<div class="name-text">
					用户名：<span class="company-text">{{msg.company?msg.company.username:'--'}}</span>
				</div>
				<div class="name-text">
					详细地址：<span class="company-text">{{msg.address}}</span>
				</div>
				<div class="name-text">
					联系电话：<span class="company-text">{{msg.mobile}}</span>
				</div>
			</div>
		</div>
		<div class="note-text">
			备注：<span class="company-text">{{msg.notes?msg.notes:'--'}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'customer-info-item',
		props: {
			msg: {}
		},
		data(){
			return {
				
			}
		},
		methods: {
			
		},
	}
</script>

<style lang="scss" scoped>
	.user-info-content{margin-top: 18px;background: #FFFFFF;padding: 30px;
		.info-title{font-size: 18px;color: #242D33;position: relative;margin-bottom: 29px;
			&:before{position: absolute;content: '';left: -30px;top: 4px;width: 4px;height: 20px;background: #3984F5;}
		}
		.text-content{
			.text-left{margin-right: 270px;
				.name-text{font-size: 12px;color: #6C7880;margin-bottom: 20px;
					.company-text{color: #242D33;}
				}
			}
			.text-right{
				.name-text{font-size: 12px;color: #6C7880;margin-bottom: 20px;
					.company-text{color: #242D33;}
				}
			}
		}
		.note-text{font-size: 12px;color: #6C7880;margin-bottom: 20px;
			.company-text{color: #242D33;}
		}
	}
</style>